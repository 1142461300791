<template>
  <div class="header-entry-box">
    <img class="header-entry-rect" src="@/assets/images/pc/rect.png" :style="`height:${imgSize}px`"/>
    <div class="header-entry-title">
      <slot>名称</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderEntry",
  components: {
  },
  props: {
    text: {
      type: Boolean,
      default: false,
    },
    imgSize: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    
  },
};
</script>

<style scoped>
.header-entry-box {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.header-entry-rect {
  width: 2px;
  height: 20px;
  margin-right: 5px;
}

.header-entry-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}
</style>
